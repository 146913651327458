import React, {Component} from 'react';
import "./styles/mokit.css";
import keittio3 from '../../img/keittio3.jpg';
import keittio2 from '../../img/keittiö2.jpg';
import takka2 from '../../img/takka2.jpg';
import kaukokuvamokista from '../../img/kaukokuvamokista.jpeg';
import makuuhuone1 from '../../img/Makuuhuone1.jpeg'
import makuuhuone2 from '../../img/Makuuhuone2.jpeg'
import parvi from '../../img/parvi1.jpg'
import wc from '../../img/wc1.jpg'
import sankyja from '../../img/sankyja.jpg'
import sauna from '../../img/sauna.jpg'
import sauna2 from '../../img/sauna2.jpeg'
import sisatila from '../../img/sisatila.jpg'
import sisatila2 from '../../img/sisatila2.jpeg'
import takapiha from '../../img/takapiha.jpg'
import terassi from '../../img/Terassi.jpeg'
import luminentiemokille from '../../img/luminentiemokille.jpg';
import nUlkokuva4 from '../../img/nUlkokuva4.jpg';
import nUlkokuva2 from '../../img/nUlko2.jpg';
import nUlkokuva3 from '../../img/nUlko3.jpg';
import nTerassi from '../../img/nTerassi.jpg';
import nMakuuhuone from '../../img/nMakuuhuone.jpg';
import nLaituri from '../../img/nLaituri.jpg';
import nLaituri2 from '../../img/nLaituri2.jpg';
import nPyora from '../../img/nPyora.jpg';
import ranta3 from '../../img/ranta3.jpg';
import ranta4 from '../../img/ranta4.jpg';


class Mokit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
    }
    loadImages() {
        if(this.state.isLoading) {
            return(
                <div>
            <div id="moki"/>
            <div id="mokit">
                <div className="mokkiContent">
                    <div className="pure-g">
                        <div className="pure-u-1 pure-u-md-1-3">
                            <h1>Rantatähti</h1>
                            <br/>
                            <p>
                                106 neliötä, neljällä makuuhuoneella 8 :lle henkilölle.
                                <br/>
                            </p>
                            <img className="img2" src={kaukokuvamokista} alt="description"/>
                        </div>
                        <div className="pure-u-1 pure-u-md-1-3">
                            <h2>Olohuone</h2>
                            <br/>
                            <p>
                                - Isot ikkunat, josta näkymä järvelle
                                <br/>
                                - Takka, taulu-tv, stereot, sohva ja nojatuolit
                            </p>
                            <img className="img" src={sisatila2} alt="description"/>
                            <img className="img" src={sisatila} alt="description"/>
                            <img className="img" src={takka2} alt="description"/>
                        </div>

                        <div className="pure-u-1 pure-u-md-1-3">
                            <h2>Keittiö</h2>
                            <br/>
                            <p>
                                - Täysin varusteltu nykyaikainen keittiö.
                                <br/>
                                - Uuni, Induktioliesi, astianpesukone, mikro, jääkaappi ja pakastin.
                                <br/>
                                - Astiasto ja ruuanvalmistusvälineet.
                            </p>
                            <img className="img" src={keittio3} alt="description"/>
                            <img className="img" src={keittio2} alt="description"/>
                        </div>

                        <div className="pure-u-1 pure-u-md-1-3">
                            <h2>Makuuhuoneet</h2>
                            <br/>
                            <p>
                                - 1x160 cm vuode
                                <br/>
                                - 2x80 cm vuode
                                <br/>
                                - 2x80 cm vuode
                                <br/>
                                - 2x90 cm vuode
                            </p>
                            <img className="img" src={makuuhuone1} alt="description"/>
                            <img className="img" src={makuuhuone2} alt="description"/>
                            <img className="img" src={sankyja} alt="description"/>
                            <img className="img" src={nMakuuhuone} alt="description"/>
                        </div>

                        <div className="pure-u-1 pure-u-md-1-3">
                            <h2>Kylpyhuone</h2>
                            <br/>
                            <p>
                                - Sähkösauna, suihku ja wc-tilat. Pyykinpesukone ja kuivauskaappi.
                            </p>
                            <img className="img" src={sauna} alt="description"/>
                            <img className="img" src={sauna2} alt="description"/>
                            <img className="img" src={wc} alt="description"/>
                        </div>

                        <div className="pure-u-1 pure-u-md-1-3">
                            <h2>Parvi</h2>
                            <br/>
                            <p>
                                - 1x laveri sohva, matka pinnasänky, taulu-tv
                            </p>
                            <img className="img" src={parvi} alt="description"/>

                        </div>
                        <div className="pure-u-1 pure-u-md-1-3">
                            <h2>Piha-alue</h2>
                            <br/>
                            <p>
                                - Reilusti tilaa pihalla. Hyvinhoidetut hiihtoladut
                                <br/>
                                - Valaistu pulkkamäki, aurinkoteranssi, grilli, oma uimaranta,
                                <br/>
                                nuotiopaikka, soutuvene, beach volley, frisbeegolf-kori, pihapelejä.
                            </p>

                        </div>
                    </div>
                    <img className="img" src={takapiha} alt="description"/>
                    <img className="img" src={nUlkokuva4} alt="description"/>

                    <img className="img" src={terassi} alt="description"/>
                    <img className="img" src={nTerassi} alt="description"/>
                    <img className="img" src={nLaituri2} alt="description"/>
                    <img className="img" src={nLaituri} alt="description"/>

                    <img className="img" src={luminentiemokille} alt="description"/>
                    <img className="img" src={nPyora} alt="description"/>
                    <img className="img" src={nUlkokuva3} alt="description"/>
                    <img className="img" src={nUlkokuva2} alt="description"/>
                    <img className="img" src={ranta3} alt="description"/>
                    <img className="img" src={ranta4} alt="description"/>
                </div>
            </div>
                </div>
            )
        }
        this.setState({
        isLoading: false
        });
    }
    render() {
        return (
            <div>
                {this.loadImages()}
            </div>
        )
    }
}

export default Mokit;
