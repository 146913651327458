import React, {Component} from 'react';


import "./styles/varausehdot.css";

class Varausehdot extends Component {

    render() {
        return (

            <body className="varausehdotbody">
            <div className="varausehdotContent">
                <div id="varausehdotd"/>
                <h1>Varausehdot</h1>
                <div className="pure-g">
                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Rantatähden varausehdot</h2>
                        <p>
                            Tehtyään varauksen asiakas saa laskun joko sähköpostiin tai kotiosoitteeseen.
                            <br/>
                            Varaus on sitova, kun ennakkovaraus 20% varauksen hinnasta on
                            maksettu vahvistuskuitissa mainittuun eräpäivään mennessä.
                            <br/>
                            Jos suoritusta ei makseta eräpäivään mennessä katsotaan varaus peruutetuksi.
                            <br/>
                            Loppulasku on maksettava kaksi viikkoa ennen vuokra-ajan alkua.
                            <br/>
                            Varauksesta muodostuu vain loppulasku, mikäli varaus tehdään alle neljä viikkoa ennen
                            oleskelun alkamista. </p>
                    </div>
                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Oleskelu lomakohteessa</h2>
                        <p>
                            Mökkiin pääsee kello 16:00 ja mökki tulee luovuttaa kello 12:00. Hintoihin sisältyy: – vesi
                            ja sähkö – takkapuut (laatikollinen) – vene rannalla – siivoukseen tarvittavat pesuaineet –
                            kaksi WC-paperirullaa ja yksi talouspaperirulla – vuodevaatteet Liinavaatteet ja pyyhkeet (1
                            iso ja 1 pieni pyyhe) voi vuokrata hintaan 20e/ hlö.
                        </p>
                    </div>
                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Loppusiivous</h2>
                        <p>
                            Loppusiivous ei sisälly mökkivuokraan, mutta sen voi halutessaan tilata omistajalta hintaan
                            95e. Vaikka loppusiivous olisikin tilattu, edellytämme, että tilat jätetään siistiin
                            kuntoon:
                            <br/>
                            – huonekalut ja muut varusteet paikoillaan ja ehjinä
                            <br/>
                            – roskat vietynä – omat ruoat, juomat, tyhjät pullot ja tölkit vietynä
                            <br/>
                            – astiat puhdistettuina ja tiskikone tyhjennettynä.
                            <br/>
                            <br/>
                            Jos et ole etukäteen tilannut loppusiivousta tehtäväksi puolestasi, yllä mainittujen töiden
                            lisäksi sinun tulee:
                            <br/>
                            – imuroida
                            <br/>
                            – siivota wc, suihkuhuone ja sauna
                            <br/>
                            – pyyhkiä pölyt.
                            <br/>
                            – puhdistaa sähkölevyt, uuni ja jääkaappi
                            <br/>
                            – viedä tuhkat takasta ulkoroskikseen Huomioithan, että myös grilli tulee jättää siistiin
                            kuntoon.
                            <br/>
                            - Muurikka ja kaasugrilli on putsattava huolellisesti käytön jälkeen.
                            <br/>
                            Jos mökille saavuttuasi huomaat puutteita esim. asunnon kunnon, siisteyden tai
                            turvallisuuden osalta, ota välittömästi yhteyttä omistajaan, jotta asia saadaan korjattua.
                            Mökkiin voi majoittua 8 henkilöä. Mökissä oleskelevien henkilöiden lukumäärä ei kuitenkaan
                            saa ylittää varattaessa sovittua lukumäärää. Juhlista ja muista tilaisuuksista, joissa
                            varauksessa sovittu henkilömäärä tilapäisesti ylittyy, tulee sopia ennakkoon omistajan
                            kanssa
                        </p>
                    </div>


                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Avainten luovutus, käyttö ja palautus</h2>
                        <p>
                            Asiakas saa käyttöönsä yhdet avaimet, jotka noudetaan huoltopalvelu NiIlo sarajärveltä
                            Vuokra-ajan lopuksi avain palautetaan noutopaikkaan.
                        </p>
                    </div>
                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Tupakointi, lemmikit, </h2>
                        <p>
                            -Tupakointi on kielletty sisätiloissa.
                            <br/>
                            -Allergia ystävällinen (Ei sallittu lemmikki eläimille.)
                        </p>
                    </div>

                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Peruutusehdot.</h2>
                        <p>
                            1. 35 päivää ennen varauksen alkamista, palautetaan ennakkovarausmaksu takaisin vähennettynä
                            käsittely kulut 45 €
                            <br/>
                            2. 28-35 päivää ennen palautetaan 50% ennakkovarauksesta.
                            <br/>
                            3. 21-28 päivää ennen pidätetään ennakkovaraus kokonaisuudessaa.
                            <br/>
                            4. 21 päivää tai vähemmän ennen varauksen alkamista peritään vuokra kokonaisuudessaan.
                            <br/>
                            5. Muissa tapauksissa: peruutusten ja korvauksen hakeminen oman vakuutuksen kautta.
                            <br/>
                            <p className={'red-p'}>Tarkista oma matkavakuutus ennen varausta.</p>
                        </p>
                    </div>

                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Mökin omistajan oikeus peruuttaa varaus </h2>
                        <p>
                            Mikäli kyseessä on ylivoimainen este, mökin omistaja voi perua varauksen. Asiakkaalla on
                            tällöin oikeus saada maksamansa summa takaisin kokonaisuudessaan. Vuokran antaja ei ole
                            velvollinen korvaamaan asiakkaalle mahdollisesti aiheutuvaa haittaa tai kuluja
                            luonnonolosuhteista, odottamattomista säänvaihteluista tai katkoksista vesi-, sähkö- tai
                            tvverkossa.
                        </p>
                    </div>

                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Vahinkojen korvaukset </h2>
                        <p>
                            Asiakas on velvollinen korvaamaan aiheuttamansa vahingot. Kadonneesta avaimesta veloitetaan
                            vähintään 30e, ja mahdolliset ylimääräiset kulut uuden avaimen teettämisestä.
                            Siivoamattomista alueista veloitamme seuraavasti: – mökistä alk. 95e – kaasugrillistä alk.
                            30e.
                        </p>
                    </div>

                    <div className="pure-u-1 pure-u-md-1-3">
                        <h2>Häiriöstä tai vaaratilanteesta johtuva vuokrasopimuksen purkaminen</h2>
                        <p>
                            Mikäli vuokralainen ei vuokranantajan tai vuokranantajan edustajan huomautuksesta huolimatta
                            lakkaa aiheuttamasta häiriötä tai vaaraa samassa tai naapurikiinteistössä oleville, on
                            vuokranantajalla tai vuokranantajan edustajalla oikeus purkaa vuokrasuhde välittömästi.
                            Kaikista ylläolevista toimenpiteistä aiheutuneet kulut laskutetaan varaajalta.

                        </p>
                    </div>
                </div>
            </div>
            </body>


        )
    }
}

export default Varausehdot;
