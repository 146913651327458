import React, {Component} from 'react';
import "./styles/aktiviteetteja.css"

class Aktiviteetteja extends Component{

    render(){
    return(
        <div className = "activitybg">
        <body className = "body">
        <div className = "activityContent">
    
        <div id = "activityd"></div>
        <h1>Aktiviteetteja</h1>
        
        <div class="pure-g">
                    
                    <div class="pure-u-1 pure-u-md-1-3"> 
                    <h2>Tekemistä mökillä</h2>
                    <p>
                    Uiminen, kalastus, veneily, beach volley, frisbeegolf-kori, maastopyöräily, retkeily, marjastus, ulko- ja sisäpelejä, pulkkamäki teranssilta, hiihtoladut mökin vierestä.    
                    <br />
                    Mahdollisuus vuokrata 2 sup lautaa.
                    </p>
                    </div>

                    <div class="pure-u-1 pure-u-md-1-3"> 
                    <h2>Tekemistä syötteellä</h2>
                    <p>
                    Laskettelu (Iso-syöte, Pikku-syöte).
                    <br/>
                    Murtomaahiihto, fatbike reitit, lumikenkäily, potkukelkkailu, moottorikelkkailu, huskytila, porotilat, luontokeskus, sisäseinä- ja kalliokiipeily, mönkijä reitit, melonta, retkeily, savusauna jousiammunta ja muut sisäakvititeetit (Hotelli pikkusyöte) & lumiareena
                    </p> 
                    </div>
                    <div class="pure-u-1 pure-u-md-1-3"> 
                    <h2>Väline vuokrausta ja ohjelma palvelut</h2>
                    <p>
                        www.syoterentsafaris.fi
                        <br/>
                     Syötteen eräpalvelut
                     <br/>
                      Hotelli Iso-Syöte
                      <br/>
                    Hotelli Pikku-Syöte
                    </p> 
                    </div>

                    <div class="pure-u-1 pure-u-md-1-3"> 
                    <h2>Ravintolat</h2>
                    <p>
                    Romekievari, Pärjä Ski Bistro & Suites, Tunturi pub, Hotelli Pikku syöte, Tovaglia, Parttio Bistro, Hotelli Iso-syöte hilltop</p>
                    </div>

                    <div class="pure-u-1 pure-u-md-1-3"> 
                    <h2>Kaupat/Kahvilat</h2>
                    <p>
                     Tunturi market, syötteen lähikauppa, <br/>
                     Pytky Cafe
                        <br/>
                     Luontokeskus, kahvila Korpi Ahmatupa (erämaa kahvila talvisin)
                    </p>
                    </div>
                </div>
</div>       
</body>
    </div>
    
    )
}
}
export default Aktiviteetteja;
