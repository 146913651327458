import React, {Component} from 'react';
import "./styles/yhteystiedot.css";

class Yhteystiedot extends Component {

    render() {

        return (
            <body className="body">
            <div className="primary">
                <div id="yhteystiedot">
                    <div className="content">
                        <div id="yhteystiedotd"/>
                        <h1 className="h1">Yhteystiedot</h1>
                        <h2 className="h2">Rantatähti</h2>
                        <p className="p">
                            Pudasjärvi / Iso-syöte</p>
                        <p>Arotie 22 </p>
                        <p>93280 Syöte</p>
                        <br/>

                        <p className="p">GSM: 040 703 4719</p>
                        <p className="p">jasaraky@gmail.com</p>
                        <br/>

                        <p className="p">
                            Reittiohjeet:
                        </p>
                        <br/>
                        <div className="pure-g">

                            <div className="pure-u-1 pure-u-md-1-3">
                                <div className="mapouter">
                                    <div className="gmap_canvas">
                                        <iframe title="myFrame" width="75%" height="500" id="gmap_canvas"
                                                src="https://maps.google.com/maps?q=sy%C3%B6tekyl%C3%A4ntie%20430&t=&z=11&ie=UTF8&iwloc=&output=embed"
                                                frameBorder="0"
                                                scrolling="no"
                                                marginHeight="0"
                                                marginWidth="0">
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </body>

        )
    }
}

export default Yhteystiedot;


