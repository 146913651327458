import React, {Component} from 'react';
import sisatila2 from '../../img/sisatila2.jpeg'
import mokkikuva from '../../img/mokkikuva.jpeg';
import "./styles/Home.css";
import terassi from '../../img/Terassi.jpeg'

class Home extends Component {

    render() {
        return (
            <div className="body">
                <div className="homeContent">
                    <img className="img" src={mokkikuva} alt="description"/>
                    <div id="homed"/>
                    <h1>Etusivu</h1>
                    <div className="pure-g">
                        <div className="boxx">
                            <div className="pure-u-1 pure-u-md-1-3">

                                <p>
                                    Tämä 2016 valmistunut korkeatasoinen rantahuvila sijaitsee 4 km:n päässä
                                    Iso-Syötteen hiihtokeskuksesta.
                                    Huvilasta avautuu järvinäköala omalla hiekkarannalla.
                                    Nykyaikainen, täysin varusteltu keittiö yhdistettynä valoisaan oleskelutilaan.
                                    Makuupaikat kahdeksalle neljällä makuuhuoneella.
                                    Kylpyhuone avaralla saunalla.
                                    Rannassa nuotiopaikka.
                                </p>
                            </div>

                        </div>


                        <div className="pure-u-1 pure-u-md-1-3">

                            <p>
                                Hyvin hoidetut hiihtoladut avautuvat suoraan ovelta. Lapsille valaistu pulkkamäki
                                terassilta.
                                Kesäaktiviteetteinä beach volley sekä frisbeegolf-kori. Rannassa käytössä soutuvene.
                                Mahdollisuus vuokarata myös SUP-lautoja.
                            </p>
                        </div>
                    </div>
                    <img className="img2" src={sisatila2} alt="description"/>
                    <img className="img2" src={terassi} alt="description"/>
                </div>
            </div>
        )
    }
}

export default Home;
