import React from 'react';
import Home from './components/Home/Home'
import Aktiviteetteja from './components/Aktiviteetteja/Aktiviteetteja'
import Mokit from './components/Mokit/Mokit'
import Yhteystiedot from './components/Yhteystiedot/Yhteystiedot'
import Hinnasto from './components/Hinnasto/Hinnasto';
import Varausehdot from './components/Varausehdot/Varausehdot';
import {Route, Switch} from "react-router";

export default ({ childProps }) =>

    <Switch>

        <Route path="/mokki" component={Mokit} props={childProps} />
        <Route path="/aktiviteetteja" component={Aktiviteetteja} props={childProps} />
        <Route path="/yhteystiedot" component={Yhteystiedot} props={childProps} />
        <Route path="/varausehdot" component = {Varausehdot} props={childProps} />
        <Route path="/hinnasto" component={Hinnasto} props={childProps}/>
        <Route exact path="/" component={Home} props={childProps}/>

    </Switch>

;
