import React, {Component} from 'react';
import './App.css';
import Footer from './Footer';
import Navbar from './Navbar';
import Routes from "./Routes";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: true,
        }
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
        };

        return (
            <div className="Appdiv">
                <Navbar/>
                <Routes childProps={childProps}/>
                <Footer/>
            </div>
        );
    }
}

export default App;
