import React, {Component} from 'react';

import "./styles/hinnasto.css";

class Hinnasto extends Component {

    render() {
        return (

            <body>
            <div id="hinasto"/>

            <div id="hinnasto">
                <div className={"nettiMokki"}>
                <h1>Rantatähti 2020 - 2021</h1>
                <br/>
                    {/*<!-- NETTIMÖKKI.COM ALKAA -->*/}
                    {/*<!-- RANTATÄHTI ID 7291 -->*/}
                    <iframe title="myHinnasto" width="300" height="480" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.nettimokki.com/bookingCalendar.php?id_cottage=7291&utm_source=widget&utm_medium=widget&utm_campaign=widget"/>
                    {/*<!-- NETTIMÖKKI.COM LOPPUU -->*/}

            </div>
                <div className="pure-g">
                    <br/>
                </div>

                <div className="hinnastopoikkeukset">

                    <p>Pääsiäinen. 1200€, &nbsp; 4vrk</p>
                    <p>Juhannus. 1200€, &nbsp; 4vrk</p>
                    <p>Joulu 1200€, &nbsp; 4vrk</p>
                    <p>Uusivuosi 1100€, &nbsp; 4vrk</p>
                    <p>Hiihtolomat 1100€, &nbsp; vko</p>
                    <p>Loppusiivous alk. 95 €</p>
                        <p>Tarkasta hinnat ja saatavuus sähköpostilla tai soittamalla</p>

                </div>

                <div className="viiva"/>
                <div className="hinnastoContent">
                    <h2>SUP-lauta vuokraus</h2>
                    <div className="pure-g">
                        <table className="pure-table">
                            <thead>
                            <tr className="tablebg4">
                                <th className="row1">Vrk</th>
                            </tr>
                            </thead>

                            <tbody>

                            <tr className="tablebg2">
                                <td className="row1">25 € / lauta</td>
                            </tr>


                            </tbody>
                        </table>
                        <br/>
                    </div>


                </div>

            </div>
            </body>

        )
    }
}

export default Hinnasto;
