import React, {Component} from 'react';
import './App.css';

class Footer extends Component{

    render(){
    return(
    <div className = "footer">
    
        
    </div>
    )
}
}
export default Footer; 