import React, {Component} from 'react';
import "./App.css"
import {Link} from "react-router-dom";
import {FaEllipsisH, FaTimesCircle} from 'react-icons/fa'

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    myFunction() {
        document.getElementById("myCheck").click();
    }

    render() {
        return (
                <div className="navheader">
                    <h2 className="syotelogo">RANTATÄHTI</h2>
                    <input type="checkbox" id="chk"/>
                    <label htmlFor="chk" className="show-menu-btn">
                        <i><FaEllipsisH/></i>
                    </label>
                    <ul className="menu">
                        <Link className={"navItem"} to="/" onClick={this.myFunction}>Etusivu</Link>
                        <Link className={"navItem"} to="/mokki" onClick={this.myFunction}>Mökki</Link>
                        <Link className={"navItem"} to="/aktiviteetteja" onClick={this.myFunction}>Aktiviteetteja</Link>
                        <Link className={"navItem"} to="/hinnasto" onClick={this.myFunction}>Hinnasto</Link>
                        <Link className={"navItem"} to="/varausehdot" onClick={this.myFunction}>Varausehdot</Link>
                        <Link className={"navItem"} to="/yhteystiedot" onClick={this.myFunction}>Yhteystiedot</Link>
                        <label htmlFor="chk" id={"myCheck"} className="hide-menu-btn">
                            <i> <FaTimesCircle/></i>
                        </label>
                    </ul>
                </div>
            
        )
    }
}

export default Navbar
